import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable()
export class SetTokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.toUpperCase().endsWith('/AUTH/LOGIN')) {
        return next.handle(request);
    }
    const setHeaders = {};
    setHeaders['Authorization'] = `Bearer ${this.auth.getToken()}`;
    setHeaders['X-Content-Type-Options'] = 'nosniff';
    setHeaders['X-Frame-Options'] = 'SAMEORIGIN';
    setHeaders['X-XSS-Protection'] = '1; mode=block';
    request = request.clone({ setHeaders });
    return next
        .handle(request).pipe()
        .pipe(
          tap((ev: HttpEvent<any>) => {
            if (ev instanceof HttpErrorResponse) {
                const resp = ev as HttpErrorResponse;
                if (resp.status === 401) {
                }
            }
            if (ev instanceof HttpResponse) {
                const resp = ev as HttpResponse<any>;
                const newToken = resp.headers.get(this.auth.TOKEN_STORAGE_NAME);
                if (newToken && newToken !== '') {
                    localStorage.setItem(this.auth.TOKEN_STORAGE_NAME, newToken);
                }
            }
          })
        );
  }
}
